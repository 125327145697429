import React from 'react';
import hero from 'assets/img/roger-square.jpg';

import classes from './Home.module.scss';

export default () => (
	<div>
		<div>
			<img src={hero} className={classes.hero} alt="Roger at Von Rothenburg's Bierstube" />
		</div>
	</div>
)
