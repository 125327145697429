import React, { useState } from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import MediaQuery from 'react-responsive';

import classes from './Calendar.module.scss';

declare var gapi: any;

interface ComponentState {
	isLoaded: boolean;
	items: {
		start: Date;
		end: Date;
		title: string;
	}[];
}

export default class Calendar extends React.Component<any, ComponentState> {
	private localizer = BigCalendar.momentLocalizer(moment);
	private API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
	private CALENDAR_ID = process.env.REACT_APP_CALENDER_ID;

	public state = {
		isLoaded: false,
		items: []
	};

	public componentDidMount = () => {
		this.getEvents();
	}

	private getEvents = () => {
		const calendarStartDate = moment().startOf('month').subtract(4, 'months').toISOString();
		const start = () => {
			gapi.client.init({
				'apiKey': this.API_KEY
			}).then(() => {
				return gapi.client.request({
					'path': `https://www.googleapis.com/calendar/v3/calendars/${this.CALENDAR_ID}/events?timeMin=${calendarStartDate}`
				})
			}).then((response: any) => {
				let events = response.result.items;
				this.setState({
					isLoaded: true,
					items: events.map((event: any) => {
						return {
							start: new Date(event.start.date || event.start.dateTime),
							end: new Date(event.end.date || event.end.dateTime),
							title: event.summary
						};
					})
				});
			}, (reason: any) => {
				console.log(reason);
			});
		}
		gapi.load('client', start)
	}

	public render() {
		return (
			<div>
				{this.state.isLoaded ?
					<div>
						<MediaQuery minWidth={768}>
							<div className={classes.calendar}>
								<BigCalendar localizer={this.localizer} events={this.state.items} />
							</div>
						</MediaQuery>
						<MediaQuery maxWidth={768}>
							<div style={{ margin: 'auto' }}>
								<BigCalendar defaultView="agenda" views={['agenda']} localizer={this.localizer} events={this.state.items} />
							</div>
						</MediaQuery>
					</div> :
					<span>Loading...</span>
				}
			</div>
		);
	}
}
