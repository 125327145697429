import React from 'react';
import Nav from './Nav';
import { Route, Switch, Redirect } from 'react-router';
import Home from './Home';
import Music from './Music';
import Contact from './Contact';
import Calendar from './Calendar';
import Bio from './Bio';
import photoCredit from 'assets/svg/photoCredit.svg';

export default () => (
	<div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
		<header>
			<Nav />
		</header>
		<section className="flex-item flex-container flex-container-vertical flex-fill-vertical flex-center">
			<Switch>
				<Route path="/home" component={Home} />
				<Route path="/music" component={Music} />
				<Route path="/contact" component={Contact} />
				<Route path="/calendar" component={Calendar} />
				<Route path="/bio" component={Bio} />
				<Redirect to="/home" from="/" />
			</Switch>
		</section>
		<footer className="flex-container" style={{ padding: '8px', alignItems: 'center', justifyContent: 'space-between' }}>
			<div>&copy; 2019 Roger Boll. All Rights Reserved.</div>
			<div>
				<a className="photo-credit" href="https://unsplash.com/@tomastuma?utm_medium=referral&amp;utm_campaign=photographer-credit&amp;utm_content=creditBadge" target="_blank" rel="noopener noreferrer" title="Download free do whatever you want high-resolution photos from Tomas Tuma">
					<span style={{ display: 'inline-block', padding: '2px 3px' }}>
						<img src={photoCredit} alt="by Tomas Tuma" />
					</span>
					<span style={{ display: 'inline-block', padding: '2px 3px' }}>Tomas Tuma</span>
				</a>
			</div>
		</footer>
	</div>
)
