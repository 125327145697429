import React from 'react';
import YouTube from 'react-youtube';
import ReactPlayer from 'react-player';

import accordion from 'assets/img/roger_accordion_2.png';
const opts = {
	height: '160',
	width: '320'
}

export default () => (
	<div className="flex-container">
		<div className="flex-item content">
			<YouTube videoId="4zF7PqURxOA" opts={opts} />
		</div>
		<div className="flex-item content">
			<YouTube videoId="kjQ-KTbwq_Q" opts={opts} />
		</div>
		<div className="flex-item content">
			<YouTube videoId="-xAcsvBfrYQ" opts={opts} />
		</div>
	</div>
)
