import React from 'react';
import kinsmen from 'assets/img/kinsmen_mac.png';

export default () => (
	<div className="flex-container">
		<div className="flex-item">
			<figure>
				<img src={kinsmen} />
				<figcaption>The Kinsmen at the Milwaukee Accordion Club</figcaption>
			</figure>
		</div>
		<div className="flex-item content">
			<p>I have been playing professionally for 40 years in and around the Milwaukee area, most recently with my band, The Kinsmen. Since retirement in 2007, I have also been playing many solo jobs at a wide variety of venues which include: senior recreation, department functions, senior apartment complexes, assisted living and nursing homes, private parties, etc.</p>
			<p>I play a digital reedless accordion which has 396 sounds built into it. Using this unique instrument, I am a one-man-band. The various genres of music in my library include well-known songs from the 1920's through the 1970's. My audiences can expect to tap their toes, sing along, and even find themselves on the dance floor! They truly enjoy hearing the music they've loved throughout their lives, hopefully as much as I enjoy playing it for them.</p>
		</div>
	</div>
)
