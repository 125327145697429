import React from 'react';

export default () => (
	<div className="flex-container flex-container-vertical">
		<div className="flex-item content centered">
			<h2>Music for any occasion!</h2>
		</div>
		<ul className="flex-item flex-container">
			<li className="flex-item">Phone: 262.284.0355</li>
			<li className="flex-item">Cell: 414.861.3240</li>
			<li className="flex-item">Email: bollacres@aol.com</li>
		</ul>
	</div>
)
