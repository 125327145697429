import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import NotFound from './NotFound';
import Main from './Main';

class App extends Component {
  render() {
    if (process.env.NODE_ENV === 'development') {
      const a11y = require('react-a11y').default;
      a11y(React, ReactDOM, {
        rules: {
          'img-uses-alt': 'warn',
          'img-redundant-alt': ['warn', ['image', 'photo']],
          'onclick-uses-role': 'warn',
          // ... add additional rules here
        }
      });
    }

    return (
      <div className="App">
        <BrowserRouter>
        <Switch>
          <Route path="/" component={Main} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
      </div >
    );
  }
}

export default App;
