import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import classes from './Nav.module.scss';

interface ComponentState {
	showMenu: boolean;
}

export default class Nav extends React.Component<any, ComponentState> {
	public state = { showMenu: false }

	private toggleMenu = () => {
		this.setState({
			showMenu: !this.state.showMenu
		});
	}

	private closeMenu = () => {
		this.setState({
			showMenu: false
		});
	}

	public render() {
		const { showMenu } = this.state;
		const menuItemsClass = showMenu ? `${classes.navbarItems} ${classes.navbarToggleShow}` : classes.navbarItems;
		return (
			<div className={classes.navbar}>
				<div className={`${classes.navbarLink} ${classes.navbarBrand}`}>
					<Link to="/" onClick={this.closeMenu}>Roger Boll</Link>
				</div>
				<div className={`${classes.navbarLink} ${classes.navbarToggle}`} role="navigation" onClick={this.toggleMenu}>
					<FontAwesomeIcon icon={faBars} />
				</div>
				<nav className={menuItemsClass}>
					<div className={classes.navbarLink}>
						<Link to="/music" onClick={this.closeMenu}>Music</Link>
					</div>
					<div className={classes.navbarLink}>
						<Link to="/contact" onClick={this.closeMenu}>Contact</Link>
					</div>
					<div className={classes.navbarLink}>
						<Link to="calendar" onClick={this.closeMenu}>Calendar</Link>
					</div>
					<div className={classes.navbarLink}>
						<Link to="bio" onClick={this.closeMenu}>Bio</Link>
					</div>
				</nav>
			</div>
		);
	}
}
